<script setup>
import moment from "moment/moment";
import { mask } from "maska";
import { ref } from "vue";
import { StarTwoTone, PhoneOutlined, CloseOutlined } from "@ant-design/icons-vue";

const props = defineProps({
  dataSource: {
    type: Array,
    default: () => [],
  },
});

const statuses = ref([
  { label: 'Оплачен', value: 'success' },
  { label: 'Не оплачен', value: 'draft' },
  { label: 'Ошибка', value: 'error' },
  { label: 'Отменён', value: 'revert' },
  { label: 'Процес оплаты', value: 'new' },
  { label: 'Процес отмены', value: 'prerevert' },
])
const emit = defineEmits(["open", "close"]);
const data = ref(props.dataSource);

function formattedBalance(balance, divide = true) {
  const formatter = new Intl.NumberFormat("RU", {
    style: "currency",
    currency: "UZS",
  });
  const formattedBalance = divide
    ? formatter.format(balance / 100)
    : formatter.format(balance);
  return formattedBalance.substring(0, formattedBalance.length - 4);
}

function formattedDate(value) {
  const diff = moment(value).diff(moment(), "days");
  const uint = number => Math.sqrt(Math.pow(number, 2));
  let date;
  if (uint(diff) > 1) {
    date = moment(value).locale("ru").format("YYYY.MM.DD в HH:mm");
  } else {
    date = moment(value).locale("ru").calendar();
  }
  return date;
}

function formattedPhone(phone) {
  return (phone) ? mask(phone, "+998(##) ###-##-##") : "";
}

function cuttedString(str, length) {
  const dots = (str && str.length > length) ? "..." : "";
  return str ? str.substring(0, length) + dots : "";
}

function ratingColor(rating) {
  switch (rating) {
    case "0":
      return "#cccccc";
    case "1":
      return "#ff3459";
    case "2":
      return "#ff8fa3";
    case "3":
      return "#e1c12c";
    case "4":
      return "#86e686";
    case "5":
      return "#00b200";
    default:
      throw new Error("Unknown rating");
  }
}

function clickPhone(value) {
  const result = `tel:+${value}`;
  const elem = document.createElement('a');
  elem.href = result;
  document.body.appendChild(elem);
  elem.click();
  emit('open', {id: null, isClickedPhone: true})
  elem?.remove();
}
</script>

<template>
  <div v-if="dataSource.length" class="fixed-control">
    <a-card
      v-for="record in dataSource"
      :key="record.id"
      :id="record['added_on'].slice(0, 10)"
      size="small"
      class="card-control"
      @click="$emit('open', {id: record.id, isClickedPhone: false})"
    >
      <div class="card-icons">
        <img width="22" :class="record.comment ? 'active' : ''" src="/icons/comment-dots.svg" alt="">
        <img width="22" :class="record.image ? 'active' : ''"  src="icons/image-outline.svg" alt="">
        <img width="20" :class="record.gif ? 'active' : ''"  src="icons/gif.svg" alt="">
        <img width="14" :class="record.audio ? 'active' : ''" class="ml-2" src="icons/mic-recording.svg" alt="">
        <img width="16" :class="record.stickers?.length ? 'active' : ''" class="ml-2" style="filter: opacity(.5)" src="icons/sticker.png" alt="">
      </div>
      <template #title>
        <p class="mb-n2 p-0 card-title">{{record.store.name}}<span v-if="record.terminal.name">&nbsp;/&nbsp;{{ record.terminal.name }}</span></p>
        <small v-if="record.employee" class="mt-n1 mb-n2 p-0 _employee-name">{{record.employee.name ? cuttedString(record.employee.name, 15) : '-'}}</small>
      </template>
      <template #extra v-if="record.added_on">
        <a style="color: gray; font-size: 13px; letter-spacing: -.2px;">
          <span class="card-date">{{ formattedDate(record.added_on) }}</span>
        </a>
      </template>
      <div class="d-flex justify-content-between card-review">
        <div class="card-review-col">
          <div class="card-item"><img width="20" src="/resources/report/tip.svg" alt=""><b class="ml-1">{{ record.order_details?.number ? '№: ' + record.order_details?.number : "—" }}</b></div>
          <div class="card-item"><img width="20" src="/resources/report/dollar.svg" alt=""><b
            class="ml-1">{{ record["paid"] ? formattedBalance(record["paid"]) : "—" }}</b></div>
          <div class="card-item"><img width="20" src="/resources/report/pig-2.svg" alt=""><b class="ml-1">{{
              record["tips_without_commission"] ? formattedBalance(record["tips_without_commission"], false) : "—"
            }}</b>
          </div>
          <div class="card-item">
            <img width="20" src="/resources/report/bill.svg" alt="">
            <b class="ml-1">{{ statuses.find(s => s.value === record['status'])?.label }}</b>
          </div>
          <div class="card-payment d-flex align-items-center">
            <img width="18" class="mr-2" src="/icons/bank.png" alt="">
            <div v-if="record['payment']" class="mt-2">
                <div class="d-flex align-items-center">
                  <img
                    :src="'resources/images/cards/' + record['payment'] + '.svg'"
                    class="card-logo cursor-pointer mr-1"
                    :class="{ bordered: record['payment'] === 'payze' }"
                    width="40"
                    alt=""
                  />
                  <span style="font-size: 12px" class="text-nowrap">{{record['card']}}</span>
                </div>
                <div v-if="record['payment_status'] === 'revert'" class="text-danger text-center mt-1">Отменен</div>
            </div>
            <b v-else>—</b>
          </div>
        </div>
        <div class="card-review-col card-review-col-2 ml-2 d-flex flex-column text-right">
          <div class="ml-auto mb-1">
            <a-button
              v-if="record.user?.phone"
              @click.stop="clickPhone(record.user?.phone)"
              class="phone-button mt-0 d-flex align-items-center"
              value="small"
            >
              <span class="ml-1 client-name">{{
                  record.user?.name ? cuttedString(record.user?.name, 15) : "—"
                }}</span>
              <template #icon>
                <PhoneOutlined />
              </template>
            </a-button>
          </div>
          <div class="text-nowrap card-item">
            <div class="d-flex align-items-center justify-content-end">
              <StarTwoTone :style="{fontSize: '18px'}" :two-tone-color="ratingColor(record.rating || '0')" />
              <CloseOutlined v-if="record.rating" style="font-size: 9px; margin-top: 2px" />
              <span>{{ record.rating ?? '-' }}</span>
            </div>
          </div>
        </div>
      </div>
    </a-card>
  </div>
</template>

<style scoped src="./mobile.css"></style>
