<template>
  <div>
    <div @click="affixFilter = !affixFilter" class="filter-btn">
      <filter-outlined style="color: #ffffff" />
    </div>
    <div class="filter-overlay" @click="affixFilter = false" :class="affixFilter ? '' : 'd-none'"></div>
    <a-collapse :class="{ 'is-mobile': isMobile }" v-model:activeKey="activeTab" :bordered="false" expand-icon-position="right">
      <template #expandIcon="{ isActive }">
        <CloseOutlined v-if="isActive" />
        <DownOutlined v-else />
      </template>
      <a-collapse-panel key="filter" header="&nbsp;">
        <a-affix :offset-top="0">
          <a-page-header :class="affixFilter ? '' : 'd-none'" class="header-affix mb-4 header-affix-filter">
            <a-form>
              <div class="row">
                <div class="col-12 mb-4">
                  <a-radio-group @change="changeSelection" :disabled="loading" v-model:value="selection">
                    <a-radio-button class="mb-2 mb-md-0" value="all">Все время</a-radio-button>
                    <a-radio-button class="mb-2 mb-md-0" value="year">Год</a-radio-button>
                    <a-radio-button class="mb-2 mb-md-0" value="month">Месяц</a-radio-button>
                    <a-radio-button class="mb-2 mb-md-0" value="week">Неделя</a-radio-button>
                    <a-radio-button class="mb-2 mb-md-0" value="yesterday">Вчера</a-radio-button>
                    <a-radio-button class="mb-2 mb-md-0" value="today">Сегодня</a-radio-button>
                  </a-radio-group>

                  <a-range-picker
                    style="width: 400px"
                    :show-time="{ format: 'HH:mm' }"
                    class="ml-md-4 report-date"
                    :disabled-date="disabledDate"
                    @change="updateDate"
                    v-model:value="dateRange"
                    v-if="selection !== 'all'"
                    :placeholder="['Выберите дату', 'Выберите дату']"
                  />
                </div>
                <div class="col-md-12 mb-2">
                  <div class="row">
                    <div class="col-md-2">
                      <a-select
                        v-model:value="query.brand_id.value"
                        :options="brandsFilter"
                        :option-filter-prop="'label'"
                        placeholder="Бренд"
                        style="width: 100%"
                        show-search
                        allow-clear
                        class="mb-2 mb-md-0"
                      />
                    </div>
                    <div class="col-md-2">
                      <a-select
                        v-model:value="query.store_id.value"
                        :options="storesFilter"
                        :option-filter-prop="'label'"
                        placeholder="Заведение"
                        style="width: 100%"
                        show-search
                        allow-clear
                        class="mb-2 mb-md-0"
                      />
                    </div>
                    <div class="col-md-2">
                      <a-select
                        v-model:value="query.terminal_id.value"
                        :options="terminalsFilter"
                        :option-filter-prop="'label'"
                        placeholder="Касса"
                        allow-clear
                        style="width: 100%"
                        show-search
                        class="mb-2 mb-md-0"
                      />
                    </div>
                    <div class="col-md-2">
                      <a-select
                        v-model:value="query.employee_id.value"
                        :options="employeesFilter"
                        :option-filter-prop="'label'"
                        placeholder="Сотрудник"
                        allow-clear
                        style="width: 100%"
                        show-search
                        class="mb-2 mb-md-0"
                      />
                    </div>
                    <div class="col-md-2">
                      <a-input class="mb-2 mb-md-0" v-model:value="searchInput" @change="searchUser" placeholder="По клиенту" />
                    </div>
                    <div class="col-md-2">
                      <a-button @click="exportExcel" type="primary" shape="round" class="float-right" :loading="exportLoading">
                        <template #icon>
                          <DownloadOutlined />
                          Экспорт в excel
                        </template>
                      </a-button>
                      <a-modal v-model:visible="exportModal" title="Экспорт отзывов" @ok="closeExportModal">
                        {{ exportStatusTitle }}
                        <a-progress
                          :stroke-color="{
                        from: '#108ee9',
                        to: '#87d068',
                      }"
                          :percent="Math.round(exportPercent)"
                          status="active"
                        />
                      </a-modal>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-md-2">
                      <a-form-item class="mb-0">
                        <a-select
                          v-model:value="query.payment_system.value"
                          :options="paymentSystemsFilter"
                          :option-filter-prop="'label'"
                          style="width: 100%"
                          show-search
                          allow-clear
                          class="mb-2 mb-md-0"
                        />
                      </a-form-item>
                    </div>
                    <div class="col-md-2">
                      <a-form-item class="mb-0">
                        <a-select
                          v-model:value="query.status.value"
                          :options="statusesFilter"
                          :option-filter-prop="'label'"
                          style="width: 100%"
                          show-search
                          class="mb-2 mb-md-0"
                        />
                      </a-form-item>
                    </div>
                    <div class="col-md-2">
                      <a-input class="mb-2 mb-md-0" v-model:value="searchByOrder" @change="searchOrder" placeholder="ID платежа" />
                    </div>
                    <div class="col-md-2">
                      <a-input class="mb-2 mb-md-0" v-model:value="searchByMerchant" @change="searchMerchant" placeholder="Юр.лицо" />
                    </div>
                    <div class="col-md-2">
                      <a-input class="mb-2 mb-md-0" v-model:value="searchByAccount" @change="searchAccount" placeholder="Номер счёта " />
                    </div>

                    <div class="col-md-2 ">
                      <a-button @click="clearFilters" class="w-100 text-right" shape="round" type="link">
                        <template #icon><CloseCircleOutlined /></template>
                        Сбросить фильтр
                      </a-button>
                    </div>

                    <div class="col-md-2 mt-4">
                      <a-select allow-сlear show-search v-model:value="query.type.value" class="mb-2 mb-md-0" placeholder="Тип" style="width: 100%">
                        <a-select-option value="">Не указано</a-select-option>
                        <a-select-option value="cashbox">Касса</a-select-option>
                        <a-select-option value="delivery">Доставка</a-select-option>
                        <a-select-option value="static">Статичный</a-select-option>
                      </a-select>
                    </div>
                    <div class="col-md-2 mt-4">
                      <a-form-item class="mb-0">
                        <a-select
                          v-model:value="query.tips.value"
                          :options="tipsSystemFilter"
                          :option-filter-prop="'label'"
                          style="width: 100%"
                          show-search
                          class="mb-2 mb-md-0"
                        />
                      </a-form-item>
                    </div>
                  </div>
                </div>
              </div>
            </a-form>
          </a-page-header>
        </a-affix>
      </a-collapse-panel>
    </a-collapse>
    <div class="row">
      <div class="col-md-4 col-12 w20percent">
        <div class="card border-0 bg-secondary text-white" v-if="loading">
          <div class="card-body">
            <a-skeleton avatar active :paragraph="{ rows: 0 }" />
          </div>
        </div>
        <div class="card border-0 bg-success text-white" v-else>
          <div class="card-body">
            <div class="d-flex flex-wrap align-items-center">
              <i class="fe fe-clipboard font-size-40 mr-3"></i>
              <div>
                <div class="font-size-20 font-weight-bold">{{ total }}</div>
                <div class="font-size-15">Всего</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-6 w20percent pr-md-3 pr-1">
        <div class="card border-0 bg-secondary text-white" v-if="billLoading">
          <div class="card-body">
            <a-skeleton avatar active :paragraph="{ rows: 0 }" />
          </div>
        </div>
        <div class="card border-0 bg-primary text-white" v-else>
          <div class="card-body">
            <div class="d-flex flex-wrap align-items-center">
              <i class="fa fa-dollar font-size-40 mr-3"></i>
              <div>
                <div class="font-size-20 font-weight-bold">{{ formattedBalance(tips) }} UZS</div>
                <div class="font-size-14">Чаевые</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-6 w20percent pl-md-3 pl-1">
        <div class="card border-0 bg-secondary" v-if="billLoading">
          <div class="card-body">
            <a-skeleton avatar active :paragraph="{ rows: 0 }" />
          </div>
        </div>
        <div class="card border-0 bg-secondary text-white" v-else>
          <div class="card-body">
            <div class="d-flex flex-wrap align-items-center">
              <i class="fa fa-dollar font-size-40 mr-3"></i>
              <div>
                <div class="font-size-20 font-weight-bold">{{ formattedBalance(bill) }} UZS</div>
                <div class="font-size-14">Счет</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-table
      v-if="!isMobile"
      :columns="columns"
      :data-source="reviewData"
      :loading="loading"
      :pagination="false"
      @change="tableChange"
      :custom-row="customRow"
      :scroll="{ x: 1500 }"
      row-key="id"
    >
      <template #date="{ text }">
        {{ formattedDate(text) }}
      </template>
      <template #rating="{ text }">
        <div v-if="text">
          <StarTwoTone :two-tone-color="ratingColor(text)" />&nbsp;{{ text }}
        </div>
      </template>
      <template #store="{ record }">
        <a-tooltip :title="record.store.name">
          <div class="ellipsis cursor-pointer pull-left">
            <span>{{ cutString(record.store.name, 15) }}</span>
            <span v-if="record.terminal?.name">&nbsp;/&nbsp;{{ record.terminal?.name }}</span>
          </div>
        </a-tooltip>
      </template>
      <template #user="{ record }">
        <a-tooltip :title="record.user?.name">
          <div class="ellipsis cursor-pointer pull-left">{{ cutString(record.user?.name, 15) }}</div>
        </a-tooltip>
        <a-button
          :href="'tel:+' + record.user?.phone"
          class="phone-button mt-2"
          v-if="record.user?.phone"
          value="small"
        >
          <template #icon><PhoneOutlined /></template>
          {{ formattedPhone(record.user?.phone) }}
        </a-button>
      </template>
      <template #employee="{ record }">
        <div v-if="record.employee">
          <a-tooltip :title="record.employee.name" v-if="!!record.employee?.['deleted']">
            <div class="ellipsis cursor-pointer pull-left">Сотрудник удален</div>
          </a-tooltip>
          <a-tooltip :title="record.employee.name" v-else>
            <div class="ellipsis cursor-pointer pull-left">{{ cutString(record.employee.name, 15) }}</div>
          </a-tooltip>
        </div>
      </template>
      <template #tips="{ record }">
        {{ formattedBalance(parseInt(record['tips_amount']) - record['commission']) }}
        <div v-if="record['tips_amount'] > 0 && record['bill_amount'] > 0">
          <span>({{ Math.round((parseInt(record['tips_amount']) - record['commission']) / record['bill_amount'] * 100) }}%)</span>
        </div>
      </template>
      <template #paid="{ record }">
        <span v-if="record['status'] === 'revert'" :class="{'text-line-through': record['bill_amount']}">
          <a-tooltip
              :title="record['bill_amount'] ? formattedBalance(record['bill_amount']) : '-'"
          >{{ record['bill_amount'] ? formattedBalance(record['bill_amount']) : '-' }}</a-tooltip>
        </span>
        <span v-if="record.paid" :style="{ color: record['status'] === 'success' ? '#595C97' : '#E0E0E0' }">
          <a-tooltip
            :title="record['payment_time']"
          >{{ formattedBalance(record.paid) }}</a-tooltip>
        </span>
      </template>
      <template #payment="{ record }">
        <div v-if="record['payment']">
          <a-tooltip :title="record['card']">
            <img
              :src="'resources/images/cards/' + record['payment'] + '.svg'"
              class="card-logo cursor-pointer"
              :class="{ bordered: record['payment'] === 'payze' }"
              width="70"
              alt=""
            />
          </a-tooltip>
        </div>
      </template>
      <template #status="{ record }">
        <a-tag
          :color="getStatusColor(record.status)"
        >
          <template #icon>
            <CheckCircleOutlined v-if="record.status === 'success'" />
            <SyncOutlined v-if="record.status === 'draft'" />
            <CloseCircleOutlined v-if="record.status === 'error'" />
            <MinusCircleOutlined v-if="record.status === 'revert'" />
            <MinusCircleOutlined v-if="record.status === 'prerevert'" />
            <ClockCircleOutlined v-if="record.status === 'new'" />
          </template>
          {{ record.status.toUpperCase() }}
        </a-tag>
      </template>
      <template #bill="{ record }">{{ record.bill }}</template>
      <template #comment="{ record }">
        <div v-if="record.comment && record.comment.length > 100" @click="showFullComment(record.id)" class="cursor-pointer">
          {{ clickedComment === record.id ? record.comment : record.comment.substring(0, 100) + '...' }}
        </div>
        <div v-else>{{ record.comment }}</div>
      </template>
    </a-table>
    <a-range-picker
      style="width: 400px"
      :show-time="{ format: 'HH:mm' }"
      class="ml-md-4 report-date"
      :disabled-date="disabledDate"
      @change="updateDate"
      v-model:value="dateRange"
      v-if="isMobile && reviewData?.length"
      :placeholder="['Выберите дату', 'Выберите дату']"
    />
    <br><br>
    <in-mobile
      v-if="isMobile"
      :data-source="reviewData"
      :loading="loading"
      @open="openModal($event)"
      @close="closeModal"
    />
    <div class="mt-4 text-right">
      <a-pagination
        show-size-changer
        v-if="total > query['per-page'].value"
        v-model:pageSize="query['per-page'].value"
        v-model:current="query.page.value"
        :total="total"
      />
    </div>
    <a-modal :width="650" :footer="null" class="review-modal" v-model:visible="detailsModal" :title="`Детали #${detailsId}`">
      <vb-bill-details @close="closeModal" :id="detailsId" :key="detailsModalKey" />
    </a-modal>
  </div>
</template>

<script setup>
import {
  CloseCircleOutlined, CloseOutlined, FilterOutlined, MinusCircleOutlined,
  PhoneOutlined, StarTwoTone, DownloadOutlined, SyncOutlined,
  DownOutlined, CheckCircleOutlined, ClockCircleOutlined,
} from '@ant-design/icons-vue';
import {onMounted, reactive, ref, toRefs, watch } from "vue";
import {useScreenSize} from "@/composables/useScreenSize";
import VbBillDetails from "@/views/report/bill-details";
import {useRoute, useRouter} from "vue-router";
import apiClient from "@/services/axios";
import InMobile from "./mobile"
import { mask } from 'maska';
import moment from "moment";

let
  detailsModal = ref(false),
  affixFilter = ref(true),
  detailsId = ref(0),
  detailsModalKey = ref(0),
  exportJobId = ref(0),
  exportPercent = ref(0),
  exportStatusTitle = ref('В очереди'),
  exportModal = ref(false),
  loading = ref(false),
  billLoading = ref(false),
  exportLoading = ref(false),
  storesFilter = ref([]),
  brandsFilter = ref([]),
  paymentSystemsFilter = ref([
    { value: "", label: "Платежная система" },
    { label: 'MULTICARD', value: 'multicard' },
    { label: 'PAYME', value: 'payme' },
    { label: 'PAYZE', value: 'payze' },
    { label: 'OSON', value: 'oson' },
    { label: 'CLICK', value: 'click' },
    { label: 'UZUM', value: 'uzum' },
    { label: 'ALIF', value: 'alif' },
    { label: 'XAZNA', value: 'xazna' },
    { label: 'ANORBANK', value: 'anorbank' },
    // { label: 'OCTO', value: 'octo' },
    // { label: 'HUMANS', value: 'humans' },
  ]),
  tipsSystemFilter = ref([
    { value: 'all', label: "Все" },
    { label: 'Чаевые', value: 'only_tips' },
    { label: 'Счет', value: 'only_bill' },
    { label: 'Чаевые и Счет', value: 'tips_and_bill' },
    { label: 'Чаевые или Счет', value: 'tips_or_bill' },
  ]),
  statusesFilter = ref([
    { label: "Все", value: 'all' },
    { label: "Завершен", value: 'finished' },
    { label: "Успешные", value: 'success' },
    { label: "Отменен", value: 'revert' },
    { label: "Не завершен", value: 'unfinished' },
  ]),
  employeesFilter = ref([]),
  terminalsFilter = ref([]),
  dateRange = ref([moment().startOf('day'), moment()]),
  selection = ref('today'),
  total = ref(0),
  tips = ref(0),
  bill = ref(0),
  timer = null,
  searchInput = ref(''),
  searchByMerchant= ref(''),
  searchByAccount= ref(''),
  searchByOrder= ref(''),
  clickedComment = ref(0),
  reviewData = ref([]);

const
  activeTab = ref(['filter']),
  router = useRouter(),
  route = useRoute(),
  initialParams = {
    page: 1,
    'per-page': 20,
    brand_id: route.query.brand_id ?? '',
    store_id: route.query.store_id ?? '',
    employee_id: route.query.employee_id ?? '',
    terminal_id: route.query.terminal_id ?? '',
    search: '',
    comment: '',
    rating: '',
    payment_system: '',
    tips: 'all',
    merchant: '',
    id: '',
    bill_id: '',
    type: '',
    status: 'finished',
    from_date: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    to_date: moment().format('YYYY-MM-DD HH:mm:ss'),
  },
  queryParams = reactive({ ...initialParams }),
  columns = [
    {
      title: 'Дата',
      dataIndex: 'added_on',
      key: 'added_on',
      sorter: true,
      slots: {
        customRender: 'date',
      },
    },
    {
      title: 'Заведение',
      dataIndex: 'store',
      key: 'store',
      sorter: true,
      width: 200,
      slots: {
        filterDropdown: 'filterDropdown',
        filterIcon: 'filterIcon',
        customRender: 'store',
      },
      onFilter: (value, record) => {
        return (record.store)
          ? record.store.name.toString().toLowerCase().includes(value.toLowerCase())
          : ''
      },
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => {
            searchInput.value.focus();
          }, 100);
        }
      },
    },
    {
      title: 'Чаевые',
      dataIndex: 'tips',
      key: 'tips',
      slots: {
        customRender: 'tips',
      },
    },
    {
      title: 'Счет',
      dataIndex: 'paid',
      key: 'paid',
      slots: {
        customRender: 'paid',
      },
    },
    {
      title: 'Платеж',
      dataIndex: 'payment',
      key: 'payment',
      slots: {
        customRender: 'payment',
      },
    },
    {
      title: 'Номер счета',
      dataIndex: 'bill',
      key: 'bill',
      slots: {
        customRender: 'bill',
      },
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      slots: {
        customRender: 'status',
      },
    },
  ],
  getStatusColor = (status) => {
    switch (status) {
      case 'success':
        return 'success';
      case 'draft':
        return 'processing';
      case 'error':
        return 'error';
      default:
        return 'default';
    }
  },
  isMobile = useScreenSize(),
  updateParams = params => {
    Object.assign(queryParams, params)
  },
  tableChange = (pag, filters, sorter) => {
    if (typeof sorter.order == "string") {
      let sign = ''
      if (sorter.order === 'descend')
        sign = '-'
      updateParams({ sort: sign + sorter.field })
    }
  },
  formattedPhone = (phone) => {
    return (phone) ? mask(phone, '+998(##) ###-##-##') : ''
  },
  formattedDate = (value) => {
    let diff = moment(value).diff(moment(), 'days');
    let uint = number =>  Math.sqrt(Math.pow(number, 2));
    let date;
    if (uint(diff) > 1) {
      date = moment(value).locale('ru').format('YYYY.MM.DD в HH:mm')
    } else {
      date = moment(value).locale('ru').calendar()
    }
    return date;
  },
  formattedBalance = (balance, divide = true) => {
    let formatter = new Intl.NumberFormat('RU', {
      style: 'currency',
      currency: 'UZS',
    });
    let formattedBalance = divide
      ? formatter.format(balance / 100)
      : formatter.format(balance)
    return formattedBalance.substring(0, formattedBalance.length - 4)
  },
  ratingColor = rating => {
    if (rating === '1')
      return '#ff3459'
    if (rating === '2')
      return '#ff8fa3'
    if (rating === '3')
      return '#e1c12c'
    if (rating === '4')
      return '#86e686'
    if (rating === '5')
      return '#00b200'
  },
  changeSelection = (event) => {
    let option = event.target.value,
      from,
      to = moment();

    if (option === 'month')
      from = moment().subtract(1, 'months').add(1, 'day')

    if (option === 'week')
      from = moment().subtract(1, 'weeks').add(1, 'day')

    if (option === 'year')
      from = moment().subtract(1, 'years').add(1, 'day')

    if (option === 'yesterday') {
      from = moment().subtract(1, 'day')
      to = moment().startOf('day')
    }

    if (option === 'today')
      from = moment()

    if (option === 'all') {
      dateRange.value = []

      updateParams({ from_date: '', to_date: '' })
      return;
    }

    from.set({hour:0,minute:0,second:0,millisecond:0})

    dateRange.value = [from.utc(true), to.utc(true)]

    updateParams({
      from_date: from.utc(true).toISOString()
        .replace('T', ' ')
        .replace('Z', '')
        .substring(0, 19),
      to_date: to.utc(true).toISOString()
        .replace('T', ' ')
        .replace('Z', '')
        .substring(0, 19),
    })
  },
  disabledDate = current => {
    return current && current > moment().endOf('day');
  },
  updateDate = (date) => {
    if (date.length) {
      const from = date[0].utc(true).toISOString()
        .replace('T', ' ')
        .replace('Z', '')
        .substring(0, 19);
      const to = date[1].utc(true).toISOString()
        .replace('T', ' ')
        .replace('Z', '')
        .substring(0, 19);
      updateParams({ from_date: from, to_date: to })
      selection.value = '';
    }
  },
  clearFilters = () => {
    Object.assign(queryParams, initialParams);

    selection.value = 'today'
    searchInput.value = ''
    searchByMerchant.value = ''
    searchByAccount.value = ''
    searchByOrder.value = ''
    dateRange.value = []
  },
  showFullComment = id => {
    clickedComment.value = id
  },
  cutString = (str, length) => {
    let dots = (str && str.length > length) ? '...' : '';
    return str ? str.substring(0, length) + dots : '';
  },
  searchUser = (e) => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      updateParams({ search: e.target.value })
    }, 800);
  },
  searchMerchant = (e) => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      updateParams({ merchant: e.target.value })
    }, 800);
  },
  searchOrder = (e) => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      updateParams({ id: e.target.value })
    }, 800);
  },
  searchAccount = (e) => {
    if (timer) {
      clearTimeout(timer);
      timer = null;
    }
    timer = setTimeout(() => {
      updateParams({ bill_id: e.target.value })
    }, 800);
  },
  exportDownload = (jobId, interval) => {
    apiClient({
      url: '/report/export-check?id=' + jobId,
      method: "GET",
    }).then((response) => {

      let reserved = +response.data.data.reserved
      let waiting = +response.data.data.waiting
      let done = +response.data.data.done

      if (waiting) {
        exportStatusTitle.value = 'Зарезервирован'
        exportPercent.value = 33
      }

      if (reserved) {
        exportStatusTitle.value = 'Выполняется'
        exportPercent.value = 66
      }

      if (done) {
        exportStatusTitle.value = 'Готово'
        exportPercent.value = 100
        clearInterval(interval)
        apiClient({
          url: '/report/export-download?id=' + jobId,
          method: "GET",
          responseType: 'blob',
        }).then((response) => {
          let filename = response.headers['content-disposition'].split('filename=')[1].split('.')[0];
          let extension = response.headers['content-disposition'].split('.')[1].split(';')[0];
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${filename}.${extension}`);
          document.body.appendChild(link);
          link.click();
        }).catch(err => {
        });
      }
    }).catch(err => {
    });
  },
  exportExcel = () => {
    exportLoading.value = true
    let params = new URLSearchParams({
      ...queryParams,
    })
    apiClient({
      url: '/report/payment-export-prepare?' + params.toString(),
      method: "GET",
    }).then((response) => {
      exportLoading.value = false
      exportModal.value = true
      exportPercent.value = 0;
      exportStatusTitle.value = 'В очереди'
      exportJobId.value = response.data.data
      let interval = setInterval(() => {
        exportDownload(response.data.data, interval)
      }, 5000)
    }).catch(err => {
      exportLoading.value = false
    });
  },
  closeExportModal = () => {
    exportModal.value = false;
  },
  getData = (params) => {
    loading.value = true
    billLoading.value = true
    apiClient.get('/report', { params }).then(({data}) => {
      reviewData.value = data.data.items;
      storesFilter.value = data.data.stores;
      brandsFilter.value = data.data.brands;
      employeesFilter.value = data.data['employees'];
      terminalsFilter.value = data.data['terminals'];
      total.value = data.data.total;
      getBillData(params)
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false;
    });
  },
  getBillData = (params) => {
    billLoading.value = true
    apiClient.get('/report/bill', { params }).then(({data}) => {
      tips.value = data.data.tips;
      bill.value = data.data.bill;
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      billLoading.value = false;
    });
  },
  openModal = ({ id, isClickedPhone = false }) => {
    if (!isClickedPhone) {
      detailsModalKey.value++;
      detailsModal.value = true;
      detailsId.value = id;
    }
  },
  customRow = (record) => {
    return {
      onClick: () => {
        openModal({id: record.id, isClickedPhone: false});
      },
    }
  },
  closeModal = () => {
    detailsModal.value = false;
    getData({
      ...queryParams,
    })
  };
onMounted(() => {
  getData({
    ...queryParams,
  });
});
onMounted(() => {
  affixFilter.value = !isMobile.value;
});
watch(queryParams, () => {
  router.push({
    name: 'report',
    query: {
      ...route.query,
      ...queryParams,
    },
  })
  getData({
    ...route.query,
    ...queryParams,
  })
});

watch(affixFilter, value => {
  document.body.style.overflow = value ? "hidden" : "auto";
});

let query = {...toRefs(queryParams)}

</script>

<style scoped>
@import "./index.css";
:deep(.ant-collapse-content-box) {
  padding: 0 !important;
}
:deep(.ant-collapse) {
  background: none !important;
  margin-bottom: 20px;
}
</style>
